import styled from "styled-components";
import { colors } from "../../../../../infrastructure/theme/colors";
// import { Button } from "../../button/button.component";
import { FlexibleDiv } from "../../../../../components/flexibleDiv/flexibleDiv.component";
// import { Input } from "../../input/input.component";

// export const PlayIconContainer = styled(FlexibleDiv)`
//   width: 30%;
//   min-width: 26rem;

//   @media (max-width: 1280px) {
//     width: 50%;
//     min-width: 0;
//   }
//   @media (max-width: 800px) {
//     width: 100%;
//     p {
//       text-align: center;
//     }
//     input {
//       width: 100%;
//       margin-bottom: 1rem;
//     }

//     button {
//       width: 100%;
//     }
//   }
// `;

export const PlayIconContainer = styled(FlexibleDiv)`
  border-radius: 50%;
  width: auto;
  padding: 2rem;
  /* position: */
  background: ${colors.primary};
  @media (max-width: 1280px) {
  }
  @media (max-width: 800px) {
  }
`;
