import styled from "styled-components";
// import { colors } from "../../../../infrastructure/theme/colors";
// import { Button } from "../../button/button.component";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

export const CardSectionWrapper = styled(FlexibleDiv)`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 6rem 4rem 4rem 4rem;
  margin-top: 5rem;
  @media (max-width: 950px) {
    padding: 3rem 2rem;
  }
  @media (max-width: 700px) {
    padding: 0;
    background-image:none;
    margin-top: 3rem;

  }
  @media (max-width: 550px) {
    /* padding: 2rem 1rem; */
  }
`;
