import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { Title } from "../../../../components/typography/text.component";
import { colors } from "../../../../infrastructure/theme/colors";

export const StartAProjectContainer = styled(FlexibleDiv)``;
export const InterestSection = styled(FlexibleDiv)`
  padding: 0;
  @media (max-width: 1000px) {
    margin-top: 1rem;
  }
  @media (max-width: 800px) {
    align-items: flex-start;
  }
`;
export const InterestHeader = styled(FlexibleDiv)`
  background: ${colors.secondary};
  padding: 1rem;
  width: max-content;
`;
export const InterestHeaderText = styled(Title)`
  font-size: 1.3rem;
  color: ${colors.primary};
`;
export const InterestButtons = styled(FlexibleDiv)`
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.2rem;

  .selected {
    background: ${colors.primary};
    color: #fff;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    button {
      font-size: 14px;
    }
  }
`;
export const Form = styled.form`
  /* background: hotpink; */
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  /* flex-direction: column; */
  width: 100%;

  @media (max-width: 1050px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;
