import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { colors } from "../../../../infrastructure/theme/colors";

// export const InternWithUsContainer = styled(FlexibleDiv)`

//   @media (max-width: 1050px) {
//   }
// `;
// export const InternWithUsInnerContainer = styled(FlexibleDiv)`
// flex-direction: column;
//   @media (max-width: 1050px) {
//   }
// `;
// export const ButtonWrapper = styled(FlexibleDiv)`
//   width: 45%;

//   @media (max-width: 1050px) {
//     width: 45%;
//   }
//   @media (max-width: 800px) {
//     width: 100%;
//   }
// `;

// ---------------------------------

export const InternWithUsContainer = styled(FlexibleDiv)`
  height: 100vh;
  align-items: center;
  background: ${colors.bg.secondary};

  @media (max-width: 1060px) {
    height: max-content;
    flex-direction: column-reverse;
    padding-top: 10vh;
    gap: 8vh;
    /* justify-content: center; */
  }
  @media (max-width: 800px) {
    padding-right: 0;
    padding-left: 0;
  }
`;
export const InternshipInfoWrapper = styled(FlexibleDiv)`
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 0;
  @media (max-width: 1060px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    /* padding-top: 20vh; */
    padding-right: 5.1%;
    padding-left: 5.1%;
  }
`;
export const ButtonWrapper = styled(FlexibleDiv)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: 1350px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1060px) {
    flex-wrap: nowrap;
  }
`;
export const InternshipImageContainer = styled(FlexibleDiv)`
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60vh;
  padding: 2rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  @media (max-width: 1200px) {
    width: 55%;
  }
  @media (max-width: 1060px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    height: 60vw;
    padding: 0;
    background-image: none;
  }
`;

export const InternshipImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;
