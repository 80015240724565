import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/* importing pages from the pages directory*/
import Home from "../../pages/Home/home.page";
import About from "../../pages/About/about.page";
import Services from "../../pages/Services/services.page";
import Contact from "../../pages/Contact/contact.page";
import Footer from "../../components/footer/footer.component";

/*Import the Header component */
import Header from "../../components/header/header.component";

const Routes = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact component={Home} path="/" />
        <Route exact component={About} path="/about" />
        <Route exact component={Services} path="/services" />
        <Route exact component={Contact} path="/contact" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
