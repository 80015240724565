import React from "react";
// import { FlexibleDiv } from "../../../../../components/flexibleDiv/flexibleDiv.component";
import ReactPlayer from "react-player/lazy";
import { colors } from "../../../../../infrastructure/theme/colors.js";
// import {
//   HorizontalSpacer,
//   Spacer,
// } from "../../../../../components/spacer/spacer.component.jsx";
import { FaPlay as Icon } from "react-icons/fa";
import { PlayIconContainer } from "./videoPlayer.styles";

const PlayIcon = () => {
  return (
    <PlayIconContainer>
      <Icon size={"2.6rem"} color={colors.white} />
    </PlayIconContainer>
  );
};

const VideoFallBack = () => {
  return (
    <iframe
      title="video-play-icon"
      style={{ border: "none" }}
      src="https://embed.lottiefiles.com/animation/74065"
    ></iframe>
  );
};
const VideoPlayer = () => {
  return (
    <ReactPlayer
      url="https://youtu.be/5dwg7ZbhJak"
      height={"100%"}
      width={"100%"}
      loop={true}
      className="videoPlayer"
      config={{
        youtube: {
          playerVars: { showinfo: 1 },
        },
      }}
      style={{
        // border: `10px solid ${colors.primary}`,
        borderRadius: "2px",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      controls={true}
      pip={true}
      playing={true}
      //   config={{
      //     youtube: {
      //       playerVars: { showinfo: 1, },
      //     },
      //     facebook: {
      //       appId: "12345",
      //     },
      //   }}
      fallback={<VideoFallBack />}
      light={true}
      playIcon={<PlayIcon />}
    />
  );
};

export default VideoPlayer;
