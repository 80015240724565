import styled from "styled-components";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";

export const RightSectionContainer = styled(FlexibleDiv)`
  align-items: flex-start;
  flex-direction: column;
  width: 30%;
  min-width: 200px;

  @media (max-width: 1060px) {
    width: 100%;
    order:1;
    margin-bottom:4rem;
    align-items:center;
    display:none;
  }
`;
export const Logo = styled.img`
  width: 60%;
  max-width: 150px;
  min-width: 100px;
  height: auto;
  object-fit: cover;
`;
