import styled from "styled-components";
import { colors } from "../../../infrastructure/theme/colors";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Text } from "../../typography/text.component";

export const BottomSectionWrapper = styled(FlexibleDiv)`
  border-top: 1px solid ${colors.iconColor};
  font-family: "Fira Sans", "Open Sans", "Helvetica Neue", sans-serif;
`;
export const BottomSectionText = styled(Text)`
  font-family: "Source Sans Pro", "Fira Sans", "Open Sans", "Helvetica Neue",
    sans-serif;
`;