import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

export const AboutUsContainer = styled(FlexibleDiv)`
  justify-content: center;
  @media (max-width: 800px) {
    padding-right: 0;
    padding-left: 0;
  }
`;
export const HeaderWrapper = styled(FlexibleDiv)`
  align-items: center;
  flex-direction: column;
  padding: 0;
  @media (max-width: 1060px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    padding-right: 5.1%;
    padding-left: 5.1%;
  }
`;
export const AboutBannerWrapper = styled(FlexibleDiv)`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 2rem;
  margin-top: 3rem;
  @media (max-width: 950px) {
    padding: 3rem 2rem;
  }
  @media (max-width: 800px) {
    padding: 0;
    background-image: none;
  }
  @media (max-width: 550px) {
    /* padding: 1rem;
    margin-top: 3rem; */
  }
`;
export const AboutBanner = styled.img`
  width: 100%;
  height: auto;
`;
