import React from "react";
import Routes from "./infrastructure/navigation/router";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
// import { ParallaxProvider } from "react-scroll-parallax";
// import { useLocation } from "react-router-dom";
// import { useParallaxController } from "react-scroll-parallax";

// const client = new ApolloClient({
//   uri: "http://localhost:1337/graphql",
//   cache: new InMemoryCache(),
// });





function App() {


  // function useUpdateControllerOnRouteChange() {
  //   const location = useLocation();
  //   const parallaxController = useParallaxController();
  
  //   useEffect(() => {
  //     parallaxController.update();
  //   }, [location.pathname]);
  // }
  
  // const ParallaxRouteUpdate = () => {
  //   useUpdateControllerOnRouteChange();
  //   return null;
  // };

  // <ParallaxProvider>
      // </ParallaxProvider>

  return (
      <div className="App">
        {/* <ApolloProvider client={client}> */}
        <Routes />
        {/* </ApolloProvider> */}
      </div>
  );
}

export default App;




