import React from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
// import { Text } from "../../typography/text.component";
import { Spacer } from "../../spacer/spacer.component";
import { Button } from "../../button/button.component";
// import { colors } from "../../../infrastructure/theme/colors";
import { Input } from "../../input/input.component";
import {
  MiddleSectionWrapper,
  FooterText,
  // , FormInput,FormButton
} from "./middleSection.styles";

export const MiddleSection = () => {
  return (
    <MiddleSectionWrapper
      paddingVertical="0"
      alignItems="flex-start"
      flexDirection="column"
    >
      {/* <FlexibleDiv paddingVertical="0"> */}
      <FooterText
        color="white"
        fontWeight="600"
        fontSize="1.1rem"
        resfontSize="1.3rem"
      >
        Our Newsletter
      </FooterText>
      <Spacer size="small" />
      <FooterText
        color="white"
        textAlign="left"
        resTextAlign="left"
        resfontSize="1.1rem"
      >
        Don’t miss any future updates of our new template and extensions and all
        the astonishing offers.
      </FooterText>
      <Spacer size="small" />
      <FlexibleDiv paddingVertical="0">
        <Input
          footerInput="70%"
          type="text"
          placeholder="youremailaddress@gmail.com"
          width="70%"
          NoExternalPadding
        />

        <Button btnText="Subscribe" width="27%" padding="1.2rem 0.5rem" />
      </FlexibleDiv>
      <Spacer size="small" />
      {/* </FlexibleDiv> */}
    </MiddleSectionWrapper>
  );
};
