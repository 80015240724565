import React from "react";
import styled from "styled-components";
import { colors } from "../../infrastructure/theme/colors";
import { fontWeights } from "../../infrastructure/theme/fonts";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Spacer } from "../spacer/spacer.component";

const InputContainer = styled(FlexibleDiv)`
  flex-direction: column;
  width: ${({ width }) => (width ? width : "100%")};
  display: ${({ display }) => (display ? display : "flex")};
  align-items: flex-start;
  padding: ${({ NoExternalPadding }) => (NoExternalPadding ? 0 : " 1.4rem 0")};
  @media (max-width: 1050px) {
    width: ${({ footerInput }) => (footerInput ? footerInput : " 100%")};
    /* width: 100%; */
  }
  @media (max-width: 800px) {
    padding: ${({ NoExternalPadding }) => (NoExternalPadding ? 0 : " 1rem 0")};
    width: 100%;
  }
`;
const Label = styled.label`
  font-size: 1rem;
  color: ${colors.text.soft};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: ${fontWeights.semiBold};
`;

const InputStyles = styled.input`
  border: ${({ border }) => (border ? border : "0")};
  width: 100%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: ${fontWeights.medium};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  padding: ${({ padding }) => (padding ? padding : "1.2rem 1rem")};
  -webkit-box-shadow: 0px 9px 15px 2px rgba(51, 51, 51, 0.05);
  -moz-box-shadow: 0px 9px 15px 2px rgba(51, 51, 51, 0.05);
  box-shadow: 0px 9px 15px 2px rgba(51, 51, 51, 0.05);
  outline: none;
  /* padding: 1rem; */
`;

export const Input = ({
  type,
  placeholder,
  border,
  fontSize,
  borderRadius,
  width,
  label,
  NoExternalPadding = false,
  footerInput,
  value,
  onChange,
  name,
  display,
}) => {
  // const [value, setValue] = useState("");

  // const onChangeInput = (e) => {
  //   setValue(e.target.value);
  //   console.log(value);
  // };

  return (
    <InputContainer
      width={width}
      NoExternalPadding={NoExternalPadding}
      footerInput={footerInput}
      display={display}
    >
      {label ? <Label fontSize={fontSize}>{label}</Label> : null}
      {label ? <Spacer /> : null}
      <InputStyles
        type={type}
        placeholder={placeholder}
        border={border}
        fontSize={fontSize}
        borderRadius={borderRadius}
        value={value}
        name={name}
        onChange={onChange}
      />
    </InputContainer>
  );
};

const TextAreaStyles = styled.textarea`
  border: ${({ border }) => (border ? border : "0")};
  width: 100%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0")};
  padding: 1rem;
  resize: none;
  outline: none;
`;

export const TextArea = ({
  type,
  placeholder,
  border,
  fontSize,
  borderRadius,
}) => {
  return (
    <TextAreaStyles
      type={type}
      placeholder={placeholder}
      border={border}
      fontSize={fontSize}
      borderRadius={borderRadius}
    />
  );
};

const DropDownStyles = styled.select`
  border: ${({ border }) => (border ? border : "0")};
  width: 100%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0")};
  color: ${colors.text.strong};
  padding: 1rem;
  outline: none;
`;

export const DropDown = ({ border, fontSize, borderRadius }) => {
  return (
    <DropDownStyles
      border={border}
      fontSize={fontSize}
      borderRadius={borderRadius}
    >
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
      <option value="vw">VW</option>
      <option value="audi" selected>
        Audi
      </option>
    </DropDownStyles>
  );
};
