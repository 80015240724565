import styled from "styled-components";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Text } from "../../typography/text.component";

export const LeftSectionWrapper = styled(FlexibleDiv)`
  /* Uncomment this when you have the rest links ready */
  /* min-width: 60%; */
  /* flex: 1; */
  /* margin-right: 4rem; */
  width: max-content;

  @media (max-width: 1060px) {
    width: 100%;
    justify-content: center;
    margin-right: 0;
    order: 3;
    margin-bottom: 2rem;
  }
`;
export const FooterTextWrapper = styled(FlexibleDiv)`
  padding: 0;
  align-items: flex-start;
  flex-direction: column;
  width: max-content;
  // background="pink"

  @media (max-width: 1060px) {

    align-items: center;
  }
`;
export const FooterText = styled(Text)`
  /* font-family: "Source Sans Pro", "Fira Sans", "Open Sans", "Helvetica Neue",
    sans-serif; */
  margin-bottom: 0.4rem;
  @media (max-width: 1060px) {
  }
`;
