import React from "react";
// import Blurb from "../blurb/blurb.component";
// import { Button } from "../button/button.component";
import { Spacer } from "../spacer/spacer.component";
// import { colors } from "../../infrastructure/theme/colors";
import {
  SectionCardsWrapper,
  Wrapper,
  CardTitle,
  CardDescription,
  CardDynamicContent,
} from "./sectionCards.styles";
// import { SlUserUnfollow as Icon } from "react-icons/sl";

export const SectionCards = ({
  title,
  titleSize,
  description,
  descriptionSize,
  Icon,
  iconSize,
  iconColor,
  background,
  border,
  color,
  space,
  minHeight,
  reff,bigger
}) => {
  return (
    <SectionCardsWrapper
      background={background}
      border={border}
      minHeight={minHeight}
      ref={reff}
      bigger={bigger}
    >
      {title ? (
        <Wrapper paddingVertical="0">
          <CardTitle titleSize={titleSize} color={color}>
            {title}
          </CardTitle>
        </Wrapper>
      ) : null}
      {/* <Spacer size="small" /> */}

      {description ? (
        <Wrapper paddingVertical="0">
          <CardDescription descriptionSize={descriptionSize} color={color}>
            {description}
          </CardDescription>
        </Wrapper>
      ) : null}
      {space ? <Spacer /> : null}
      {Icon ? (
        <Wrapper paddingVertical="0">
          <CardDynamicContent>
            <Icon
              size={iconSize ? iconSize : "2rem"}
              color={iconColor ? iconColor : "#fff"}
            />
          </CardDynamicContent>
        </Wrapper>
      ) : null}
    </SectionCardsWrapper>
  );
};
