import styled from "styled-components";
import { colors } from "../../infrastructure/theme/colors";
import { fontWeights } from "../../infrastructure/theme/fonts";
// import { Button } from "../../button/button.component";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title } from "../typography/text.component";

export const SectionCardsWrapper = styled(FlexibleDiv)`
  width: 30%;
  height: max-content;
  background: ${({ background }) => (background ? background : colors.primary)};
  flex-direction: column;
  border: ${({ border }) => (border ? border : `2px solid ${colors.primary}`)};
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  @media (max-width: 1460px) {
    min-height: ${({ bigger }) => (bigger ? "35vh" : "150px")};
  }
  @media (max-width: 1250px) {
    width: ${({ bigger }) => (bigger ? "45%" : "30%")};
    min-height: ${({ bigger }) => (bigger ? "30vh" : "150px")};
  }
  @media (max-width: 950px) {
    width: 45%;
    /* margin-bottom:1.5rem; */
  }
  @media (max-width: 800px) {
    width: 100%;
    min-height: max-content;
  }
  @media (max-width: 700px) {
    padding:1rem;
  }
`;

export const Wrapper = styled(FlexibleDiv)`
  justify-content: center;
  align-items: center;
  img {
    width: 70%;
    height: auto;
    @media (max-width: 800px) {
      width: 50%;
    }
  }
`;

export const CardTitle = styled(Title)`
  font-size: ${({ titleSize }) => (titleSize ? titleSize : "1rem")};
  font-weight: ${fontWeights.bold};
  color: ${({ color }) => (color ? color : `${colors.white}`)};
  text-align: center;

  @media (max-width: 1460px) {
    line-height:2rem;
  }
`;

export const CardDescription = styled(Text)`
  font-size: ${({ descriptionSize }) =>
    descriptionSize ? descriptionSize : "0.9rem"};
  color: ${({ color }) => (color ? color : `${colors.white}`)};
  text-align: center;
`;

export const CardDynamicContent = styled(Text)``;

// export const CardTitle = styled(Text)``;
