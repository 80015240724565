import styled from "styled-components";
import { colors } from "../../../../infrastructure/theme/colors";
// import { fontWeights } from "../../../../infrastructure/theme/fonts";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { Text /*, Title */} from "../../../../components/typography/text.component";

export const StartWithItechnologyContainer = styled(FlexibleDiv)`
  /* height: 100vh; */
  align-items: center;
  @media (max-width: 1050px) {
    flex-direction:column;
  }
`;
export const HeaderInfo = styled(FlexibleDiv)`
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 0;
  @media (max-width: 1050px) {
    width: 100%;
    margin-bottom:2.6rem;
  }
`;
export const CardContainer = styled(FlexibleDiv)`
  justify-content: center;
  align-items: center;
  width: 50%;
  height: max-content;
  padding: 0;
  
  @media (max-width: 1050px) {
    width: 100%;
  }
`;

export const ActionCard = styled(FlexibleDiv)`
  width: 100%;
  height: max-content;
  background: ${colors.primary};
  flex-direction: column;
  padding: 3rem;
  border-radius: 12px;
  /* margin-bottom: 1rem; */

  @media (max-width: 800px) {
    width: 100%;
    padding:2rem;
  }
`;

export const BulletPointWrapper = styled(FlexibleDiv)`
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
`;
export const BulletPoints = styled(FlexibleDiv)`
  justify-content: flex-start;
  padding: 1rem 0;
  flex-wrap:nowrap;
  align-items:flex-start;
  @media (max-width: 800px) {
    flex-direction: row;
  }
 
`;

export const Point = styled(Text)`
  font-size: ${({ pointSize }) => (pointSize ? pointSize : "1.2rem")};
  font-weight: 600;
  color: ${colors.white};
  word-wrap: wrap;
  text-align: left;
  margin-left: 1rem;
  @media (max-width: 800px) {
    margin-left: 0.7rem;
  }
`;

export const CardSummaryTextWrapper = styled(FlexibleDiv)`
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem 0;
`;

export const CardSummaryText = styled(Text)`
  font-size: ${({ pointSize }) => (pointSize ? pointSize : "1rem")};
  color: ${colors.white};
`;

//   export const CardTitle = styled(Title)`
//     font-size: ${({ titleSize }) => (titleSize ? titleSize : "1rem")};
//     font-weight: ${fontWeights.bold};
//     color: #fff;
//   `;
