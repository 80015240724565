import React from "react";
import Blurb from "../../../../components/blurb/blurb.component";
// import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

import {
  AboutUsContainer,
  HeaderWrapper,
  AboutBannerWrapper,
  AboutBanner,
} from "./aboutItechnology.styles";
import AboutBannerImage from "../../../../assets/images/about.png";

const AboutItechnology = () => {
  return (
    <AboutUsContainer
      paddingVertical="3rem"
      boxedLayout
      id="About"
      // background={colors.bg.secondary}
    >
      <HeaderWrapper>
        <Blurb width="70%">
          <h5>Get to know us!</h5>
          <h3>About Innovation Technology Terrain</h3>
          <p>
            We partner with ambitious CEOs and teams to help them design iconic
            brands and experiences so that they can delight their audiences.
          </p>
        </Blurb>
      </HeaderWrapper>

      <AboutBannerWrapper>
        <AboutBanner src={AboutBannerImage} alt="About Us Banner" />
      </AboutBannerWrapper>
    </AboutUsContainer>
  );
};

export default AboutItechnology;
