import styled from "styled-components";
import { colors } from "../../../../infrastructure/theme/colors";
// import { fontWeights } from "../../../../infrastructure/theme/fonts";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
// import { Text, Title } from "../../../../components/typography/text.component";

export const ClientContainer = styled(FlexibleDiv)`
  background: ${colors.bg.secondary};
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;
export const ClientInnerContainer = styled(FlexibleDiv)`
  padding: 0;
  flex-direction: column;

`;
export const ClientImageWrapper = styled(FlexibleDiv)`
  @media (max-width: 800px) {
    flex-direction: row;
    justify-content: center;
    gap:1rem;
  }
`;
