import React from "react";
import Blurb from "../../../../components/blurb/blurb.component.jsx";
// import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component.jsx";

import { Button } from "../../../../components/button/button.component";
// import { Spacer } from "../../../../components/spacer/spacer.component";
import { colors } from "../../../../infrastructure/theme/colors.js";
import {
  ContactUsContainer,
  Headercontainer,
  ButtonWrapper,
} from "./contactUs.styles";

const ContactUs = () => {
  const title = "Looking for custom Development? We are ready to help.",
    description =
      "We are ready to help. Reach us with your custom requirements or any kind of issues.";
  return (
    <ContactUsContainer boxedLayout id="Contact">
      <Headercontainer>
        <Blurb textAlign="left" alignItems="flex-start">
          <h5>Contact Us Now!</h5>
          <h3>{title}</h3>
          <p>{description}</p>
        </Blurb>
      </Headercontainer>

      <ButtonWrapper justifyContent="center">
        <Button btnText="Contact Us" btnColor={colors.white} width="100%" />
      </ButtonWrapper>
    </ContactUsContainer>
  );
};

export default ContactUs;
