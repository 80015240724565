import React from "react";
import Blurb from "../../../../components/blurb/blurb.component";
// import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { ImageTextCard } from "../../../../components/ImageTextCard/imageTextCard.component";
// import BEOLogo from "../../../../assets/images/Blessed-Emoefe.svg";
import Google from "../../../../assets/images/googleAssistant.svg";
import Chike from "../../../../assets/images/chike-trivia.png";
import Embed from "../../../../assets/images/EMBED1.png";
import { Spacer } from "../../../../components/spacer/spacer.component";
// import { colors } from "../../../../infrastructure/theme/colors";
import {
  ClientContainer,
  ClientInnerContainer,
  ClientImageWrapper,
} from "./clients.styles";

const Clients = () => {
  const data = [
    { image: Google },
    { image: Embed },
    { image: Google },
    { image: Embed },
    { image: Chike },
  ];
  return (
    <ClientContainer boxedLayout id="Clients">
      <ClientInnerContainer>
        <Blurb width="70%">
          <h5>Our Trusted Clients</h5>
          <h3>Collaboration Is at the Epicenter of What we do!</h3>
          <p>
            We have collaborated with many business owners and teams to develop
            softwares that works for them.
          </p>
        </Blurb>
        <Spacer size="small" />
        <ClientImageWrapper paddingVertical="0" boxedLayout>
          {data.map(({ label, image }) => (
            <ImageTextCard image={image} resWidth={"30%"} />
          ))}
        </ClientImageWrapper>
      </ClientInnerContainer>
    </ClientContainer>
  );
};

export default Clients;
