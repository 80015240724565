import React from "react";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

import Blurb from "../../../../components/blurb/blurb.component";
// import { colors } from "../../../../infrastructure/theme/colors";
// import Slider from "../../../../components/Slider";
// import GamifiedLearning from "../../../../assets/images/h1.png";
// import Image2 from "../../../../assets/images/h3.png";
// import Image3 from "../../../../assets/images/h5.png";
import Image1 from "../../../../assets/images/h2.png";
import { Text } from "../../../../components/typography/text.component";
// import {  Spacer } from "../../../../components/spacer/spacer.component";

import {TestimonialCard,
  TextCarouselWrap,
  ImageContainer,
  InfoSection,
  QuoteWrapper,
  PersonalInfoWrapper,
  Name,
  Hyphen,
  Position,
  HeaderText,
  TextContainer,
} from "./testimonials.styles";
import ScrollingDiv from "./Carousel";
import { generateID } from "../../../../infrastructure/lib/generateID";
// import { fontWeights } from "../../../../infrastructure/theme/fonts";
import { colors } from "../../../../infrastructure/theme/colors";

import { FaQuoteLeft as QuoteOpenIcon, FaQuoteRight as QuoteCloseIcon } from "react-icons/fa";

const Testimonials = () => {
  const cardData = [
    {
      number: "01",
      year: "2020",
      quote:
        "  I learned that courage was not the absence of fear, but the triumph over it. The brave man is not he who does not feel afraid, but he who conquers that fear.",
      author: "Nelson Mandela",
    },
    {
      number: "02",
      year: "2020",
      quote:
        " The seeds of success in every nation on Earth are best planted in women and children.",
      author: "Joyce Banda",
    },
    {
      number: "03",
      year: "2020",
      quote:
        " Education is for improving the lives of others and for leaving your community and world better than you found it",
      author: "Marian Wright Edelman",
    },
    {
      number: "04",
      year: "2020",
      quote:
        "  I learned that courage was not the absence of fear, but the triumph over it. The brave man is not he who does not feel afraid, but he who conquers that fear.",
      author: "Nelson Mandela",
    },
  ];
  return (
    <FlexibleDiv justifyContent="center" id="Testimonials">
      <FlexibleDiv paddingVertical="0" justifyContent="center">
        <Blurb width="70%">
          <h5>Testimonials</h5>
          <h3>What clients say about our work!</h3>
        </Blurb>
      </FlexibleDiv>
      <FlexibleDiv paddingVertical="0">
        <ScrollingDiv
          scrollCardBg="transparent"
          scrollOuterBg="transparent"
          autoScroll={false}
          scrollInnerWidth={"80%"}
        >
          {cardData.map((item, i) => (
            <TestimonialCard key={generateID(15)}>
              <TextCarouselWrap>
                <ImageContainer>
                  <img src={Image1} alt="er" width="100%" height="100%" />
                </ImageContainer>
                <InfoSection>
                  <QuoteWrapper>
                    <QuoteOpenIcon size="2rem" color={colors.secondary} />
                  </QuoteWrapper>
                  <PersonalInfoWrapper>
                    <Name>ken Kakashi</Name>
                    <Hyphen>-</Hyphen>
                    <Position>Product Designer</Position>
                  </PersonalInfoWrapper>
                  <HeaderText>Our Client's Amazing Review</HeaderText>
                  <TextContainer paddingVertical="0">
                    <Text
                      fontSize="15px"
                      className="cardText"
                      color={colors.white}
                      textAlign="left"
                    >
                      "It is a distinct pleasure for me to recommend Deep
                      Technologys Limited to anyone and all interested parties.
                      They have been professional, comprehensive and competent
                      throughout the process of our working together.”
                    </Text>
                  </TextContainer>

                  <QuoteWrapper justifyContent="flex-end">
                    <QuoteCloseIcon size="2rem" color={colors.secondary} />
                  </QuoteWrapper>
                </InfoSection>
              </TextCarouselWrap>
            </TestimonialCard>
          ))}
        </ScrollingDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Testimonials;
