import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { colors } from "../../../../infrastructure/theme/colors";

export const ContactUsContainer = styled(FlexibleDiv)`
 background:${colors.bg.secondary};
 @media (max-width: 1050px) {
      justify-content: center;
  }
`;
export const Headercontainer = styled(FlexibleDiv)`
  width: 60%;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;
export const ButtonWrapper = styled(FlexibleDiv)`
  width: 20%;

  @media (max-width: 1050px) {
    width: 45%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;
