import React from "react";
import Blurb from "../../../../components/blurb/blurb.component.jsx";
import { Button } from "../../../../components/button/button.component";
// import { Spacer } from "../../../../components/spacer/spacer.component";
import { colors } from "../../../../infrastructure/theme/colors.js";
import InternshipImg from "../../../../assets/images/logo.png";

import {
  InternWithUsContainer,
  InternshipInfoWrapper,
  ButtonWrapper,
  InternshipImageContainer,
  InternshipImage,
} from "./internWithUs.styles";

const InternWithUs = () => {
  return (
    <InternWithUsContainer id="Internship" boxedLayout>
      <InternshipImageContainer>
        <InternshipImage src={InternshipImg} alt="InternshipImage" />
      </InternshipImageContainer>
      <InternshipInfoWrapper>
        <Blurb textAlign="left" alignItems="flex-start">
          <h5>Intern With Us</h5>
          <h3>Our internship Programme</h3>
          <p>
            We partner with ambitious CEOs and business owners to help them
            design and build examplary brands, experiences and products.
          </p>
        </Blurb>

        <ButtonWrapper>
       
          <Button
            btnText="Apply for Internship"
            background={colors.transparent}
            border={`2px solid ${colors.primary}`}
            btnColor={colors.primary}
            width="100%"
          />
        </ButtonWrapper>
      </InternshipInfoWrapper>
    </InternWithUsContainer>
  );
};

export default InternWithUs;

// <Button
//         btnText="Apply For Internship"
//         background={colors.transparent}
//         border={`2px solid ${colors.primary}`}
//         btnColor={colors.primary}
//         width="100%"
//       />
