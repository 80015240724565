import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const FooterContainer = styled(FlexibleDiv)`
  /* font-family:  "Fira Sans", "Open Sans", "Helvetica Neue", sans-serif; */
`;
export const TopSection = styled(FlexibleDiv)`
justify-content: space-between;

 @media (max-width: 1060px) {
    flex-direction:column;
  }
`;