import React from "react";
// import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
// import { Text } from '../../typography/text.component';
import { Spacer } from "../../spacer/spacer.component";
import { colors } from "../../../infrastructure/theme/colors";
import {
  LeftSectionWrapper,
  FooterTextWrapper,
  FooterText,
} from "./leftSection.styles";
import { generateID } from "../../../infrastructure/lib/generateID";

export const LeftSection = () => {
  const linkData = [
    {
      title: "Company",
      links: [
        { linkLabel: "Hire Expert", linkUrl: "#" },
        { linkLabel: "About", linkUrl: "#" },
        { linkLabel: "Why Us", linkUrl: "#" },
        { linkLabel: "Clients", linkUrl: "#" },
        { linkLabel: "Testimonials", linkUrl: "#" },
        { linkLabel: "How It Works", linkUrl: "#" },
        { linkLabel: "Services", linkUrl: "#" },
      ],
    },
    // {
    //   title: "Our Products",
    //   links: [
    //     { linkLabel: "Mall Verse", linkUrl: "#" },
    //     { linkLabel: "Proxy", linkUrl: "#" },
    //     { linkLabel: "Social Laugh", linkUrl: "#" },
    //     { linkLabel: "MaxTerm", linkUrl: "#" },
    //     { linkLabel: "Virtual Lab", linkUrl: "#" },
    //     { linkLabel: "Social Laugh", linkUrl: "#" },
    //     { linkLabel: "MaxTerm", linkUrl: "#" },
    //     { linkLabel: "Virtual Lab", linkUrl: "#" },
    //   ],
    // },
    // {
    //   title: "Support",
    //   links: [
    //     { linkLabel: "About", linkUrl: "#" },
    //     { linkLabel: "Portfolio", linkUrl: "#" },
    //     { linkLabel: "Contact Us", linkUrl: "#" },
    //     { linkLabel: "Services", linkUrl: "#" },
    //     { linkLabel: "Hire Us", linkUrl: "#" },
    //   ],
    // },
    // {
    //   title: "Company",
    //   links: [
    //     { linkLabel: "About", linkUrl: "#" },
    //     { linkLabel: "Portfolio for men", linkUrl: "#" },
    //     { linkLabel: "Contact Us", linkUrl: "#" },
    //     { linkLabel: "Services", linkUrl: "#" },
    //     { linkLabel: "Hire Us", linkUrl: "#" },
    //   ],
    // },
  ];

  return (
    <LeftSectionWrapper paddingVertical="0" alignItems="flex-start">
      {linkData.map(({ title, links }) => (
        <FooterTextWrapper key={generateID(15)}>
          <FooterText
            color="white"
            fontWeight="600"
            fontSize="1.1rem"
            resfontSize="1.3rem"
          >
            {title}
          </FooterText>
          <Spacer />
          {links.map(({ linkLabel, linkUrl }) => (
            <FooterText
              key={generateID(15)}
              color={colors.iconColor}
              fontSize="1rem"
              resfontSize="1.1rem"
            >
              {linkLabel}
            </FooterText>
          ))}

          <Spacer size="small" />
        </FooterTextWrapper>
      ))}
    </LeftSectionWrapper>
  );
};
