import styled from "styled-components";

export const LogoWrapper = styled.div`
  width: auto;
  height: auto;
  :hover {
    cursor: pointer;
  }
  img {
    width: auto;
    height: 3rem;
  }
`;
