export const colors = {
  bg: {
    primary: "#f5f5f5",
    secondary: "#EFF1F7",
  },
  primary: "#3624B6",
  secondary: "#fff951",
  iconColor: "#a3a3a3",
  text: {
    strong: "#141414",
    medium: "#202020",
    soft: "#3D3D3D",
  },
  white: "#fff",
  black: "#000",
  transparent: "transparent",
};
