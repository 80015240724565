import styled from "styled-components";
import { colors } from "../../infrastructure/theme/colors";
// import { theme } from "../../infrastructure/theme/index";
import { lineHeights } from "../../infrastructure/theme/lineHeight";
import { fontSizes, fontWeights } from "../../infrastructure/theme/fonts";

export const Text = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : fontSizes.smallTitle)};
  font-weight: ${({ fontWeight }) =>
    fontWeight ? fontWeight : fontWeights.medium};
  color: ${({ color }) => (color ? color : colors.text.strong)};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  line-height: ${({ lineHeight }) => lineHeight || lineHeights.paragraph};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};

  @media (max-width: 1050px) {
    font-size: ${({ resfontSize }) => (resfontSize ? resfontSize : "0.8rem")};
    margin: ${({ resmargin }) => (resmargin ? resmargin : "")};
    text-align: ${({ resTextAlign }) =>
      resTextAlign ? resTextAlign : "center"};
  }
`;

export const Title = styled.h1`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : fontSizes.h1)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "auto")};
  color: ${({ color }) => (color ? color : colors.black.strong)};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  line-height: ${({ lineHeight }) => lineHeight || lineHeights.title};
  /* @media (max-width: 1050px) {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.8rem")};
  } */

  @media (max-width: 1050px) {
    margin: ${({ resmargin }) => (resmargin ? resmargin : "")};
    text-align: ${({ resTextAlign }) =>
      resTextAlign ? resTextAlign : "center"};
  }
`;
