import React from "react";
import { colors } from "../../infrastructure/theme/colors";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
// import { Text } from "../typography/text.component";
// import Blurb from "../blurb/blurb.component";
// import { Spacer, HorizontalSpacer } from "../spacer/spacer.component";


import { LeftSection } from "./leftSection/leftSection.component";
import { RightSection } from "./rightSection/rightSection.component";
import { MiddleSection } from "./middleSection/middleSection.component";
import { BottomSection } from "./bottomSection/bottomSection.component";
import { FooterContainer, TopSection } from "./footer.styles";

 const Footer = () => {
  return (
    <FooterContainer
      boxedLayout
      background={colors.black}
      paddingVertical="3rem"
    >
      <TopSection alignItems="flex-start" paddingVertical="0">
        <LeftSection />
        <MiddleSection />
        <RightSection />
      </TopSection>
      <FlexibleDiv justifyContent="center" paddingVertical="0">
        <BottomSection />
      </FlexibleDiv>
    </FooterContainer>
  );
};

export default Footer;
