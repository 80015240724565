import React from "react";
// import ReactPlayer from "react-player/lazy";
import Blurb from "../../../../components/blurb/blurb.component.jsx";
import { colors } from "../../../../infrastructure/theme/colors.js";
// import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component.jsx";
import { Button } from "../../../../components/button/button.component";
import {
  HorizontalSpacer,
  // Spacer,
} from "../../../../components/spacer/spacer.component.jsx";
// import { FaPlay as Icon } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer/videoPlayer.section.jsx";

import {
  HeroContainer,
  HeroInfoWrapper,
  ButtonWrapper,
  HeroVideoContainer,
} from "./hero.styles";

const Hero = () => {
  return (
    <HeroContainer boxedLayout>
      <HeroInfoWrapper>
        <Blurb textAlign="left" alignItems="flex-start">
          <h5>Innovation Technology Terrain</h5>
          <h3>Redefining Impossible & Building exemplary products.</h3>
          <p>
            We partner with ambitious CEOs and business owners to help them
            design and build examplary brands, experiences and products.
          </p>
        </Blurb>

        <ButtonWrapper>
          <Button
            btnText="Start a project"
            border={`2px solid ${colors.primary}`}
            btnColor={colors.white}
            width="100%"
          />
          <HorizontalSpacer size={"small"} />
          <Button
            btnText="Apply for Internship"
            background={colors.transparent}
            border={`2px solid ${colors.primary}`}
            btnColor={colors.primary}
            width="100%"
          />
        </ButtonWrapper>
      </HeroInfoWrapper>

      <HeroVideoContainer>
        <VideoPlayer />
      </HeroVideoContainer>
    </HeroContainer>
  );
};

export default Hero;
