import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../../components/typography/text.component";
import { colors } from "../../../../infrastructure/theme/colors";
import { fontWeights } from "../../../../infrastructure/theme/fonts";

export const TestimonialCard = styled(FlexibleDiv)`
  margin: 0 16%;
  /* min-width: 400px; */
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  flex-shrink: 0;
  padding: 2%;
  width: 68%;
  @media (max-width: 1600px) {
    margin: 0 10%;
    width: 80%;
  }

  @media (max-width: 1100px) {
    margin: 0 3%;
    width: 94%;
  }
  @media (max-width: 900px) {
    margin: 0 3%;
    width: 94%;
  }
  @media (max-width: 700px) {
    padding: 0;
    background-image: none;
    /* margin-top: 3rem; */
  }
`;
export const TextCarouselWrap = styled(FlexibleDiv)`
  width: max-content;
  width: 100%;
  min-width: 400px;
  background: ${colors.primary};

  flex-shrink: 0;
  justify-content: space-around;
  padding: 2rem;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1rem;
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 600px) {
    min-width: auto;
  }
`;

export const ImageContainer = styled(FlexibleDiv)`
  border-radius: 50%;
  padding: 0;
  width: max-content;
  height: max-content;
  /* background: ${colors.white}; */
  border: 1rem solid ${colors.secondary};

  img {
    border-radius: 50%;
    width: 25vh;
    height: 25vh;
    margin: 7px;
    object-fit: cover;
  }
  @media (max-width: 1600px) {
    border: 0.5rem solid ${colors.secondary};
    img {
      width: 12vw;
      height: 12vw;
    }
  }
  @media (max-width: 900px) {
    margin-bottom:1rem;
  }
  @media (max-width: 600px) {
  }
`;

export const InfoSection = styled(FlexibleDiv)`
  padding: 0;
  width: 60%;
  @media (max-width: 900px) {
    width: 100%;
    flex-direction:column;
  }
  @media (max-width: 600px) {
  }
`;
export const QuoteWrapper = styled(FlexibleDiv)`
  padding: 0;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  /* background:yellow; */

  @media (max-width: 900px) {
     justify-content:center;
  }
  @media (max-width: 600px) {
  }
`;
export const PersonalInfoWrapper = styled(FlexibleDiv)`
  padding: 0;
  justify-content: flex-start;

  @media (max-width: 900px) {
    justify-content:center;
    flex-direction:row;
  }
  @media (max-width: 600px) {
  }
`;
export const Name = styled(Text)`
  padding: 0;
  font-size: 1rem;
  font-weight: ${fontWeights.bold};
  color: ${colors.white};
  text-align: left;

  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 600px) {
  }
`;
export const Hyphen = styled(Text)`
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: ${fontWeights.bold};
  color: ${colors.white};
`;
export const Position = styled(Text)`
  padding: 0;
  font-size: 1rem;
  font-weight: ${fontWeights.thin};
  color: ${colors.white};

  @media (max-width: 800px) {
  }
  @media (max-width: 600px) {
  }
`;
export const HeaderText = styled(Text)`
  padding: 1rem 0;
  font-size: 2rem;
  font-weight: ${fontWeights.bold};
  color: ${colors.white};
  text-align: left;

  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 600px) {
  }
`;
// export const InfoSection = styled(FlexibleDiv)`
//   padding: 0;
//   width:70%;
//   @media (max-width: 800px) {
//   }
//   @media (max-width: 600px) {
//   }
// `;

export const TextContainer = styled(FlexibleDiv)`
  border-radius: 10px;
  /* border: 2px solid ${colors.secondary}; */
  padding: 0.2rem 0;
  @media (max-width: 1000px) {
    .cardText {
      text-align: left;
    }
  }
  @media (max-width: 900px) {
    .cardText {
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    .cardText {
    }
  }
`;
